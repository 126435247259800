import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';
import Tooltip from '../Tooltip/Tooltip';
import Icon from '../Icon';

const InputEl = props => {
  const { inputRef, ...rest } = props;
  // eslint-disable-next-line react/prop-types
  if (props.mask) {
    return (
      <InputMask
        {...rest}
        inputRef={inputRef}
        alwaysShowMask={false}
        maskChar={null}
        formatChars={{
          '8': '[2-9]', // for first character of phone numbers, can't be 1 or 0 in North America
          '9': '[0-9]',
          a: '[A-Za-z]',
          '*': '[A-Za-z0-9]'
        }}
      />
    );
  } else {
    return <input ref={inputRef} {...rest} />;
  }
};

const Input = props => {
  const {
    className,
    autoComplete,
    customErrorMessage,
    disabled,
    id,
    hasErrors,
    helpText,
    icon,
    inputRef,
    invisibleLabel,
    label,
    name,
    mask,
    menu,
    showMenuArrow,
    min,
    max,
    maxLength,
    placeholder,
    readonly,
    required,
    showRequiredIndicator,
    visuallyRequired,
    step,
    testId,
    type,
    pattern,
    units,
    value,
    onChange,
    onInvalid,
    retainInvaildOnChange,
    onBlur,
    onFocus,
    onKeyDown,
    toolTipText,
    shouldCheckValidityOnBlur
  } = props;

  const [isInvalid, setIsInvalid] = useState(hasErrors);
  const [fieldValue, setFieldValue] = useState(value ?? '');

  // Update value if it is updated externally
  useEffect(() => {
    if (value !== fieldValue) {
      if (value === null || typeof value === 'undefined') {
        setFieldValue('');
      } else {
        setIsInvalid(false);
        setFieldValue(value);
      }
    }
  }, [value]);

  useEffect(() => {
    setIsInvalid(hasErrors);
  }, [hasErrors]);

  const handleChange = e => {
    setFieldValue(e.target.value);
    if (isInvalid && !retainInvaildOnChange) {
      setIsInvalid(false);
    }

    if (onChange) {
      onChange(e);
    }
  };

  const handleInvalid = e => {
    if (customErrorMessage) {
      e.preventDefault();
    }
    setIsInvalid(true);

    if (onInvalid) {
      onInvalid(e);
    }
  };

  const handleBlur = e => {
    if (onBlur) {
      onBlur(e);
    }

    if (shouldCheckValidityOnBlur !== false) {
      e.target.checkValidity();
    }
  };

  return (
    <div
      className={`mb-6 last:mb-0 ${isInvalid ? 'has-error' : ''}`}
      data-testid={testId ? `${testId}-container` : null}
    >
      <label
        htmlFor={id}
        className={`control-label ${
          invisibleLabel ? 'sr-only' : 'block relative'
        }`}
        data-testid={testId ? `${testId}-label` : null}
      >
        {((required && showRequiredIndicator) || visuallyRequired) && (
          <>
            <abbr title="This field is required.">*</abbr>{' '}
          </>
        )}
        {label}
        {showRequiredIndicator === false && !required && (
          <span
            className="text-muted ml-2"
            style={{ fontWeight: 'normal', fontSize: '.85em' }}
          >
            {'(optional)'}
          </span>
        )}
        {units && (
          <span
            className="text-muted text-sm font-normal ml-2"
            data-testid={testId ? `${testId}-label-units` : null}
          >
            {units}
          </span>
        )}
        {toolTipText && (
          <Tooltip text={toolTipText}>
            <i className="glyphicon glyphicon-question-sign" />
          </Tooltip>
        )}
      </label>
      <div className="relative">
        {icon && (
          <div
            className={`absolute top-4 left-4 z-10 pointer-events-none ${
              isInvalid ? 'text-red' : 'text-brand-primary'
            }`}
          >
            <Icon name={icon} size={20} />
          </div>
        )}
        <InputEl
          className={`form-control ${icon ? 'pl-16' : ''} ${
            showMenuArrow ? 'pr-12' : ''
          } ${className}`}
          id={id}
          type={type}
          name={name}
          value={fieldValue}
          placeholder={placeholder}
          mask={mask}
          autoComplete={autoComplete}
          onFocus={onFocus}
          onBlur={handleBlur}
          onChange={handleChange}
          onInvalid={handleInvalid}
          onKeyDown={onKeyDown}
          min={min}
          max={max}
          maxLength={maxLength}
          step={step}
          disabled={disabled ? 'disabled' : null}
          readOnly={readonly}
          pattern={pattern}
          inputRef={inputRef}
          required={required}
          data-testid={testId ? `${testId}-input` : null}
        />
        {showMenuArrow && (
          <div className="absolute top-4 right-4 z-10 text-color text-lg leading-tight pointer-events-none">
            &#9662;
          </div>
        )}
      </div>
      {menu}
      {isInvalid && customErrorMessage && (
        <div
          className="help-block error-message"
          data-testid={testId ? `${testId}-errormessage` : null}
        >
          {customErrorMessage}
        </div>
      )}
      {helpText && (
        <div
          className="help-block"
          data-testid={testId ? `${testId}-helptext` : null}
        >
          {helpText}
        </div>
      )}
    </div>
  );
};

Input.defaultProps = {
  disabled: false,
  showRequiredIndicator: true,
  type: 'text',
  className: ''
};

Input.propTypes = {
  autoComplete: PropTypes.string,
  customErrorMessage: PropTypes.string,
  disabled: PropTypes.bool,
  icon: PropTypes.node,
  id: PropTypes.string.isRequired,
  hasErrors: PropTypes.bool,
  inputRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  invisibleLabel: PropTypes.bool,
  helpText: PropTypes.string,
  label: PropTypes.node.isRequired,
  mask: PropTypes.string, // mask library docs here: https://github.com/sanniassin/react-input-mask/tree/v2
  menu: PropTypes.node,
  min: PropTypes.string,
  max: PropTypes.string,
  maxLength: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  readonly: PropTypes.bool,
  required: PropTypes.bool,
  showMenuArrow: PropTypes.bool,
  showRequiredIndicator: PropTypes.bool,
  visuallyRequired: PropTypes.bool,
  step: PropTypes.string,
  testId: PropTypes.string,
  type: function(props, propName, componentName) {
    if (
      !/(text|date|email|number|search|tel|time|url|password)/.test(
        props[propName]
      )
    ) {
      /* istanbul ignore next */
      return new Error(
        `Invalid prop '${propName}' of value '${
          props[propName]
        }' supplied to ${componentName}, expected one of ["text", "date", "email", "number", "search", "tel", "time", "url"]. ${
          props[propName] === 'hidden'
            ? 'To create a hidden input, use the <HiddenInput /> component'
            : props[propName] === 'password'
            ? 'To create a password input, use the <PasswordInput /> component'
            : ''
        }`
      );
    }
  },
  pattern: PropTypes.string,
  units: PropTypes.node,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onInvalid: PropTypes.func,
  retainInvaildOnChange: PropTypes.bool,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyDown: PropTypes.func,
  toolTipText: PropTypes.string,
  shouldCheckValidityOnBlur: PropTypes.bool,
  className: PropTypes.string
};

export default Input;
