import React from 'react';
import PropTypes from 'prop-types';

const HiddenInput = React.forwardRef((props, ref) => {
  // Destructure the props. Supports adding additional props so that
  // this component can be used as a drop-in replacement for the
  // standard <input type="hidden" /> element.
  const { id, name, value, ...inputProps } = props;

  return (
    <input
      type="hidden"
      id={id}
      name={name}
      value={value ?? ''}
      ref={ref}
      {...inputProps}
    />
  );
});

HiddenInput.displayName = 'HiddenInput';

HiddenInput.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string
};

export default HiddenInput;
