import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import getAddressObjectFromPlace from '../../../googleUtils/getAddressObjectFromPlace';
import { renderCoordinatesString } from '../../utils';
import HiddenInput from '../HiddenInput/HiddenInput';
import LocationAutocomplete from './LocationAutocomplete';

const LocationInput = props => {
  const {
    autocompleteOptions,
    autoCompleteValue,
    customErrorMessage,
    fields,
    idPrefix,
    handleLocationChange,
    onInvalid,
    hasErrors,
    helpText,
    hideHelpTextWhenError,
    initialPlaceId,
    inputKey,
    inputName,
    label,
    mapContext,
    namePrefix,
    onPhoneChange,
    onPlaceChange,
    placeholder,
    preciseLocation,
    removeDefaultNotes,
    required,
    showRequiredIndicator,
    visuallyRequired,
    testId,
    value,
    handleRecentLocationNotes
  } = props;

  const wrapper = useRef();

  const defaultFields = {
    id: '',
    description: '',
    google_place_id: '',
    geo_lat: '',
    geo_lng: '',
    country: '',
    iso_country_code: '',
    name: '',
    street: '',
    neighborhood: '',
    city: '',
    state: '',
    postal_code: '',
    default_notes: '',
    saved_location_label: '',
    manual_location: false
  };

  const [hiddenFields, setHiddenFields] = useState({
    ...defaultFields,
    ...fields
  });
  const [isInvalid, setIsInvalid] = useState(hasErrors);
  const [errorMessage, setErrorMessage] = useState(customErrorMessage || '');

  useEffect(() => {
    if (customErrorMessage) {
      setErrorMessage(customErrorMessage);
    }
  }, [customErrorMessage]);

  useEffect(() => {
    if (isInvalid && errorMessage && onInvalid) {
      onInvalid(errorMessage);
    }
  }, [isInvalid, errorMessage]);

  useEffect(() => {
    if (preciseLocation) {
      setHiddenFields({
        ...defaultFields,
        ...preciseLocation,
        manual_location: true
      });
      if (isInvalid) {
        setIsInvalid(false);
      }
    }
  }, [JSON.stringify(preciseLocation)]);

  const handleChange = () => {
    if (isInvalid) {
      setIsInvalid(false);
    }
  };

  const handleInvalid = e => {
    e.preventDefault();
    setIsInvalid(true);
  };

  const handleBlur = e => {
    e.target.checkValidity();
  };

  const getGeo = place => {
    if (place.geometry) {
      return {
        lat: place.geometry.location?.lat(),
        lng: place.geometry.location?.lng()
      };
    } else {
      setIsInvalid(true);
      setErrorMessage('The location for this is missing. Please try another.');
      return { lat: null, lng: null };
    }
  };

  const validateAddress = address => {
    if (
      !address.country ||
      !address.street ||
      !address.city ||
      !address.state ||
      !address.zip
    ) {
      setErrorMessage('Please enter a more specific location.');
      setIsInvalid(true);
    } else {
      if (!hasErrors) {
        setErrorMessage('');
      }
      setIsInvalid(false);
    }
  };

  const processPlace = (inputValue, place, isInitialLoad) => {
    const address = getAddressObjectFromPlace(place);
    const { lat, lng } = getGeo(place);
    const fields = {
      description: inputValue,
      google_place_id: place.place_id.toString(),
      geo_lat: lat.toString(),
      geo_lng: lng.toString(),
      country: address.country,
      iso_country_code: address.country,
      name: address.name,
      phone: address.phone_number,
      street: address.street,
      neighborhood: address.neighborhood,
      city: address.city,
      state: address.state,
      postal_code: address.zip,
      default_notes: !removeDefaultNotes ? place.default_notes : '',
      saved_location_label: place.saved_location_label,
      manual_location: place.manual_location
    };

    mapContext?.setAddress?.(address);
    mapContext?.setDefaultNotes?.(place?.default_notes);

    if (place.geometry) {
      setIsInvalid(false);
    } else {
      setIsInvalid(true);
    }
    setHiddenFields({ ...defaultFields, ...fields });

    if (!isInitialLoad && onPhoneChange) {
      onPhoneChange(address.phone_number ?? null);
    }

    if (onPlaceChange) {
      onPlaceChange(lat, lng);
    }

    if (handleLocationChange) {
      handleLocationChange(fields, isInitialLoad);
    }

    validateAddress(address);
  };

  const removePlace = () => {
    setIsInvalid(false);
    setHiddenFields({ ...defaultFields });
    if (handleLocationChange) {
      handleLocationChange(null);
    }
  };

  const shouldDisplayHelpText = () => {
    if (!helpText) return false;

    if (hideHelpTextWhenError) {
      if ((isInvalid || hasErrors) && errorMessage) return false;
    }

    return true;
  };

  return (
    <div
      ref={wrapper}
      className={`mb-6 last:mb-0 ${isInvalid || hasErrors ? 'has-error' : ''}`}
      data-testid={testId ? `${testId}-container` : null}
    >
      <label
        htmlFor={idPrefix}
        className="control-label"
        data-testid={testId ? `${testId}-label` : null}
      >
        {((required && showRequiredIndicator) || visuallyRequired) && (
          <>
            <abbr title="This field is required.">*</abbr>{' '}
          </>
        )}
        {label}
        {showRequiredIndicator === false && !required && (
          <span
            className="text-muted ml-2"
            style={{ fontWeight: 'normal', fontSize: '.85em' }}
          >
            {'(optional)'}
          </span>
        )}
      </label>
      <LocationAutocomplete
        inputClasses="form-control string"
        inputValue={
          hiddenFields.manual_location
            ? `${renderCoordinatesString(hiddenFields)} (near ${
                hiddenFields.description
              })`
            : value
            ? value
            : ''
        }
        // the key is used if you want to force the input to update externally (re-render)
        key={inputKey}
        inputName={inputName}
        inputId={idPrefix}
        isInvalid={hasErrors || isInvalid}
        onBlur={handleBlur}
        onInvalid={handleInvalid}
        onChange={handleChange}
        required={required}
        autoCompleteValue={autoCompleteValue ?? 'disable-autocomplete'}
        initialPlaceId={initialPlaceId}
        placeholder={placeholder}
        savedLocations={autocompleteOptions.saved_locations}
        recentLocations={autocompleteOptions.recent_locations}
        $wrapper={wrapper.current}
        handleRecentLocationNotes={handleRecentLocationNotes}
        onSelect={(inputValue, item, isInitialLoad) => {
          // Exit early if the location is being set from the map.
          // This makes sure it works properly if there is an address set on page load.
          if (isInitialLoad && preciseLocation) return;

          if (item) {
            processPlace(inputValue, item, isInitialLoad);
            return;
          }
          // there is no item, we want to clear the fields
          if (inputValue === '') {
            removePlace();
          }
        }}
      />
      {Object.keys(hiddenFields).map(field => {
        return (
          <HiddenInput
            key={field}
            id={`${idPrefix}-${field}`}
            name={`${namePrefix}[${field}]`}
            value={hiddenFields[field]?.toString()}
          />
        );
      })}
      {(isInvalid || hasErrors) && errorMessage && (
        <div
          className="help-block error-message"
          data-testid={testId ? `${testId}-errormessage` : null}
        >
          {errorMessage}
        </div>
      )}
      {shouldDisplayHelpText() && (
        <div
          className="help-block"
          data-testid={testId ? `${testId}-helptext` : null}
        >
          {helpText}
        </div>
      )}
    </div>
  );
};

LocationInput.defaultProps = {
  showRequiredIndicator: true,
  value: ''
};

LocationInput.propTypes = {
  autocompleteOptions: PropTypes.object,
  autoCompleteValue: PropTypes.string,
  customErrorMessage: PropTypes.string,
  fields: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number])
  ),
  idPrefix: PropTypes.string.isRequired,
  initialPlaceId: PropTypes.string,
  hasErrors: PropTypes.bool,
  helpText: PropTypes.node,
  hideHelpTextWhenError: PropTypes.bool,
  label: PropTypes.node.isRequired,
  inputName: PropTypes.string.isRequired,
  inputKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  mapContext: PropTypes.shape({
    setAddress: PropTypes.func,
    setDefaultNotes: PropTypes.func
  }),
  handleLocationChange: PropTypes.func,
  onInvalid: PropTypes.func,
  namePrefix: PropTypes.string.isRequired,
  onPhoneChange: PropTypes.func,
  onPlaceChange: PropTypes.func,
  placeholder: PropTypes.string,
  preciseLocation: PropTypes.PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number])
  ),
  removeDefaultNotes: PropTypes.bool,
  required: PropTypes.bool,
  showRequiredIndicator: PropTypes.bool,
  visuallyRequired: PropTypes.bool,
  testId: PropTypes.string,
  value: PropTypes.string,
  handleRecentLocationNotes: PropTypes.func
};

export default LocationInput;
