import React, { useState } from 'react';
import PropTypes from 'prop-types';
import useUpdateEffect from '../../hooks/useUpdateEffect';

const Textarea = props => {
  const {
    autoFocus,
    customErrorMessage,
    disabled,
    id,
    hasErrors,
    helpText,
    label,
    name,
    onChange,
    onInvalid,
    maxLength,
    placeholder,
    rows,
    required,
    showRequiredIndicator,
    style,
    testId,
    value
  } = props;

  const [isInvalid, setIsInvalid] = useState(hasErrors);
  const [fieldValue, setFieldValue] = useState(value ?? '');

  useUpdateEffect(() => {
    if (value === null || typeof value === 'undefined') {
      setFieldValue('');
    } else {
      setIsInvalid(false);
      setFieldValue(value);
    }
  }, [value]);

  const handleChange = e => {
    setFieldValue(e.target.value);
    if (isInvalid) {
      setIsInvalid(false);
    }

    if (onChange) onChange(e);
  };

  const handleInvalid = e => {
    if (customErrorMessage) {
      e.preventDefault();
    }
    setIsInvalid(true);

    if (onInvalid) {
      onInvalid(e);
    }
  };

  const handleBlur = e => {
    e.target.checkValidity();
  };

  return (
    <div
      className={`mb-6 last:mb-0 ${isInvalid ? 'has-error' : ''}`}
      data-testid={testId ? `${testId}-container` : null}
    >
      <label htmlFor={id} data-testid={testId ? `${testId}-label` : null}>
        {required && showRequiredIndicator && (
          <>
            <abbr title="This field is required.">*</abbr>{' '}
          </>
        )}
        {label}
        {showRequiredIndicator === false && !required && (
          <span
            className="text-muted ml-2"
            style={{ fontWeight: 'normal', fontSize: '.85em' }}
          >
            {'(optional)'}
          </span>
        )}
      </label>
      <textarea
        className="form-control"
        id={id}
        name={name}
        value={fieldValue}
        maxLength={maxLength}
        placeholder={placeholder}
        rows={rows}
        onBlur={handleBlur}
        onChange={handleChange}
        onInvalid={handleInvalid}
        disabled={disabled ? 'disabled' : null}
        required={required}
        data-testid={testId ? `${testId}-textarea` : null}
        autoFocus={autoFocus} // eslint-disable-line
        style={style}
      />
      {isInvalid && customErrorMessage && (
        <div
          className="help-block error-message"
          data-testid={testId ? `${testId}-errormessage` : null}
        >
          {customErrorMessage}
        </div>
      )}
      {helpText && (
        <div data-testid={testId ? `${testId}-helptext` : null}>{helpText}</div>
      )}
    </div>
  );
};

Textarea.defaultProps = {
  disabled: false,
  showRequiredIndicator: true,
  type: 'text'
};

Textarea.propTypes = {
  autoFocus: PropTypes.bool,
  customErrorMessage: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  hasErrors: PropTypes.bool,
  helpText: PropTypes.string,
  label: PropTypes.node,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onInvalid: PropTypes.func,
  maxLength: PropTypes.number,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  rows: PropTypes.number,
  showRequiredIndicator: PropTypes.bool,
  testId: PropTypes.string,
  value: PropTypes.string,
  style: PropTypes.shape({
    resize: PropTypes.string
  })
};

export default Textarea;
