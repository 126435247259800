import React, { useState } from 'react';
import PropTypes from 'prop-types';

const Tooltip = props => {
  const {
    text,
    children,
    className,
    offset = 5,
    offsetH = 0,
    placement = 'top',
    pointerOffset,
    tooltipClassName,
    width = 200
  } = props;

  const [showMessage, setShowMessage] = useState(false);

  const handleMouseEnter = () => {
    setShowMessage(true);
  };

  const handleMouseLeave = () => {
    setShowMessage(false);
  };

  const toolTipStyles = {
    width: width,
    left: '50%'
  };

  if (placement === 'top') {
    toolTipStyles.bottom = `calc(100% + ${offset}px)`;
    toolTipStyles.marginLeft = -(width / 2) + offsetH;
  } else if (placement === 'right') {
    toolTipStyles.left = '100%';
    toolTipStyles.top = '50%';
    toolTipStyles.transform = 'translateY(-50%)';
  } else if (placement === 'bottom-right') {
    toolTipStyles.top = `calc(100% + ${offset}px)`;
    toolTipStyles.left = '100%';
    toolTipStyles.marginLeft = '-18px';
  }

  return (
    <>
      <span
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className={`ml-2 ${className ?? ''}`}
        style={{ position: 'relative' }}
      >
        {children}
        {showMessage && (
          <div
            className={`tooltip in ${placement} ${tooltipClassName ?? ''}`}
            style={{ ...toolTipStyles }}
          >
            <div
              className="tooltip-arrow"
              style={{
                [pointerOffset ? 'left' : '']: `calc(50% + ${pointerOffset}px)`
              }}
            />
            <div className="tooltip-inner">{text}</div>
          </div>
        )}
      </span>
    </>
  );
};

Tooltip.propTypes = {
  text: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  offset: PropTypes.number,
  offsetH: PropTypes.number,
  placement: PropTypes.string,
  pointerOffset: PropTypes.number,
  tooltipClassName: PropTypes.string,
  width: PropTypes.number
};

export default Tooltip;
