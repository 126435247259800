import React from 'react';
import PropTypes from 'prop-types';

const Checkbox = props => {
  const {
    className,
    checked,
    id,
    label,
    name,
    onChange,
    testId,
    disabled
  } = props;

  return (
    <label className={`custom-checkbox ${className}`}>
      <input
        type="checkbox"
        id={id}
        name={name}
        className="custom-checkbox__input"
        onChange={onChange}
        checked={checked}
        disabled={disabled}
        data-testid={testId ? `${testId}-checkbox` : null}
      />
      <span className="custom-checkbox__label">{label}</span>
    </label>
  );
};

Checkbox.propTypes = {
  className: PropTypes.string,
  checked: PropTypes.bool,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  name: PropTypes.string,
  label: PropTypes.node.isRequired,
  onChange: PropTypes.func,
  testId: PropTypes.string,
  disabled: PropTypes.bool
};

export default Checkbox;
